import { showError, showErrorOptional } from "@/utils/notificationUtils";
import { SiteProfile } from "./../../../api/interfaces/SitesInterfaces";
import { useStore } from "@/store/store";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { viewSiteProfile } from "@/api/SitesApi";
import { MetadataMutations } from "@/store/metadataModule";

export default defineComponent({
  name: "TabsParentSite",
  props: {},
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const siteId = route.params.id as string;
    const site = ref<SiteProfile>();

    viewSiteProfile(siteId)
      .then(async (siteProfile) => {
        store.commit(MetadataMutations.SET_BREAD_CRUMBS_NAME, siteProfile.name);

        site.value = siteProfile;
      })
      .catch((ex) => {
        showErrorOptional("Site is not found", ex);
      });

    const parts = route.fullPath.split("/").filter((p) => p);

    return {
      site,
      activeTab: route.name !== "View Site" ? parts[parts.length - 1] : "",
      changeTab(tabName: any) {
        router.push(`/sites/view-site/${siteId}/${tabName.props.name.trim()}`);
      },
    };
  },
});
