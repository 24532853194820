import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = $event)),
      onTabClick: _ctx.changeTab
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "Site information",
          name: ""
        }),
        _createVNode(_component_el_tab_pane, {
          label: "Presets",
          name: "studies"
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"]),
    (_ctx.site)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          site: _ctx.site
        }, null, 8, ["site"]))
      : _createCommentVNode("", true)
  ]))
}